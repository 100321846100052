@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.product-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
/* .product-image-gradient {
  position: relative !important;
} */
.product-image-gradient::before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  background: radial-gradient(rgba(63, 94, 251, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.thumbs-wrapper {
  overflow-x: auto !important;
  margin: 10px 0 0 0 !important;
}
